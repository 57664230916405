/* tslint:disable */
/* eslint-disable */
/**
 * TM36 Mon Entreprise
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ConversationAccountDto } from './ConversationAccountDto';
import {
    ConversationAccountDtoFromJSON,
    ConversationAccountDtoFromJSONTyped,
    ConversationAccountDtoToJSON,
} from './ConversationAccountDto';
import type { FileInfoDto } from './FileInfoDto';
import {
    FileInfoDtoFromJSON,
    FileInfoDtoFromJSONTyped,
    FileInfoDtoToJSON,
} from './FileInfoDto';

/**
 * 
 * @export
 * @interface MessageDto
 */
export interface MessageDto {
    /**
     * 
     * @type {number}
     * @memberof MessageDto
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof MessageDto
     */
    createdDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof MessageDto
     */
    updatedDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof MessageDto
     */
    deletedDate: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof MessageDto
     */
    readDate: Date | null;
    /**
     * 
     * @type {ConversationAccountDto}
     * @memberof MessageDto
     */
    author: ConversationAccountDto;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    content: string;
    /**
     * 
     * @type {FileInfoDto}
     * @memberof MessageDto
     */
    fileInfo: FileInfoDto | null;
}

/**
 * Check if a given object implements the MessageDto interface.
 */
export function instanceOfMessageDto(value: object): value is MessageDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('createdDate' in value) || value['createdDate'] === undefined) return false;
    if (!('updatedDate' in value) || value['updatedDate'] === undefined) return false;
    if (!('deletedDate' in value) || value['deletedDate'] === undefined) return false;
    if (!('readDate' in value) || value['readDate'] === undefined) return false;
    if (!('author' in value) || value['author'] === undefined) return false;
    if (!('content' in value) || value['content'] === undefined) return false;
    if (!('fileInfo' in value) || value['fileInfo'] === undefined) return false;
    return true;
}

export function MessageDtoFromJSON(json: any): MessageDto {
    return MessageDtoFromJSONTyped(json, false);
}

export function MessageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessageDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdDate': (new Date(json['createdDate'])),
        'updatedDate': (new Date(json['updatedDate'])),
        'deletedDate': (json['deletedDate'] == null ? null : new Date(json['deletedDate'])),
        'readDate': (json['readDate'] == null ? null : new Date(json['readDate'])),
        'author': ConversationAccountDtoFromJSON(json['author']),
        'content': json['content'],
        'fileInfo': FileInfoDtoFromJSON(json['fileInfo']),
    };
}

export function MessageDtoToJSON(value?: MessageDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'createdDate': ((value['createdDate']).toISOString()),
        'updatedDate': ((value['updatedDate']).toISOString()),
        'deletedDate': (value['deletedDate'] == null ? null : (value['deletedDate'] as any).toISOString()),
        'readDate': (value['readDate'] == null ? null : (value['readDate'] as any).toISOString()),
        'author': ConversationAccountDtoToJSON(value['author']),
        'content': value['content'],
        'fileInfo': FileInfoDtoToJSON(value['fileInfo']),
    };
}

